body {
    background-color: #f8f9fa;
}

.bold-text {
    font-weight: bold;
    font-size: 20px;
}

.progress-bar-container {
    margin-bottom: 20px;
    height: 30px;
}

.custom-progress-bar .progress-bar {
    font-size: 14px;
}

.circle-button {
    border-radius: 50%;
    font-size: 18px;
    text-align: center;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
    border: 2px solid #007bff;
    background-color: #007bff;
    color: #ffffff;
    transition: all 0.3s ease;
}

.circle-button:hover,
.circle-button:focus,
.circle-button:active {
    background-color: #0056b3 !important;
    color: #ffffff !important;
    outline: none;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
    border: 2px solid #0056b3;
}

.card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
}

.card-header {
    background-color: #007bff;
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.card-body {
    padding: 2rem;
}

.alert-info {
    background-color: #e9f5ff;
    border-color: #b8e2ff;
    color: #004085;
}

.display-4 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #007bff;
}

.lead {
    font-size: 1.1rem;
    color: #6c757d;
}

.accordion-button:not(.collapsed) {
    background-color: #e9f5ff;
    color: #004085;
}

.custom-list {
    margin-left: 20px;
    padding-left: 0;
}

.custom-list li {
    margin-bottom: 10px;
    padding-left: 0;
    text-align: left;
}

@media (max-width: 576px) {
    .bold-text {
        font-size: 16px;
    }

    .progress-bar-container {
        height: 25px;
    }

    .custom-progress-bar .progress-bar {
        font-size: 12px;
    }

    .circle-button {
        font-size: 16px;
        width: 120px;
        height: 120px;
    }

    .card-header {
        font-size: 20px;
    }

    .display-4 {
        font-size: 2rem;
    }

    .lead {
        font-size: 1rem;
    }
}

.note-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5rem;
}

.note-icon {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e9f5ff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
}

.note-icon i {
    font-size: 1.5rem;
    color: #007bff;
}

.note-content {
    flex-grow: 1;
}

.note-content h4 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    color: #007bff;
}

.note-content p {
    font-size: 0.9rem;
    color: #6c757d;
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .note-item {
        margin-bottom: 2rem;
    }
}

.start-button-container,
.progress-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.start-button {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    border: none;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
}

.start-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

.start-button span {
    display: block;
    line-height: 1.2;
}

@media (max-width: 576px) {
    .start-button {
        width: 150px;
        height: 150px;
        font-size: 20px;
    }
}

.progress-circle {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    border: 5px solid #007bff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.progress-circle-bar {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 5px solid #007bff;
    clip: rect(0, 160px, 160px, 80px);
}

.progress-circle-progress {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 5px solid #007bff;
    clip: rect(0, 80px, 160px, 0);
}